import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import useJournalStore from '../../../state/journals';
import useTagStore from '../../../state/tags';
import SearchFilter from './search-filter';
import { BeatLoader } from 'react-spinners';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import { ContentState } from 'draft-js';
import { useJournalData } from '../../../lib/custom-hooks';
import { useCallback } from 'react';

const Timeline = ({ tagName }) => {
  const navigate = useNavigate();
  const setPage = useJournalStore((state) => state.setPage);
  const page = useJournalStore((state) => state.page);
  const { loading: loading2 } = useJournalData();
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchTag, setSearchTag] = useState(tagName || '');
  const [searchMonthYear, setSearchMonthYear] = useState('');
  const getJournalIdsByTag = useTagStore((state) => state.getJournalIdsByTag);
  const hasMore = useJournalStore((state) => state.hasMore);
  const fetchTags = useTagStore((state) => state.fetchTags);
  const journals = useJournalStore((state) => state.journals);
  const [filteredList, setFilteredList] = useState(journals);

  const tags = useTagStore((state) => state.tags);

  useEffect(() => {
    setFilteredList(journals);
  }, [journals]);

  useEffect(() => {
    if (searchMonthYear) {
      setFilteredList(journals.filter((j) => j['date'].slice(0, 7) === searchMonthYear));
    } else {
      setFilteredList(journals);
    }
  }, [searchMonthYear]);
  useEffect(() => {
    if (searchTag) {
      const tag = getJournalIdsByTag(searchTag);
      const tagJournalIdSet = new Set(tag?.journals.map((j) => j.journalId));
      setFilteredList(journals.filter((journal) => tagJournalIdSet.has(journal._id)));
    } else {
      setFilteredList(journals);
    }
  }, [searchTag]);

  useEffect(() => {
    if (searchText) {
      const searchTokens = searchText.split(' ').filter(Boolean).join('|');
      const searchRegex = new RegExp(searchTokens, 'gim');
      setFilteredList(journals.filter((j) => j.content.match(searchRegex)));
    } else {
      setFilteredList(journals);
    }
  }, [searchText]);

  useEffect(() => {
    setLoading(true);
    fetchTags().finally(() => setLoading(false));
  }, []);

  // infinite loader logic
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading || loading2 || [searchMonthYear, searchTag, searchText].some(Boolean)) {
        return;
      }
      if (observer?.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, loading2, hasMore, searchMonthYear, searchTag, searchText]
  );

  return (
    <div>
      <SearchFilter
        searchText={searchText}
        setSearchText={setSearchText}
        tags={tags}
        setSearchTag={setSearchTag}
        searchTag={searchTag}
        searchMonthYear={searchMonthYear}
        setSearchMonthYear={setSearchMonthYear}
      />
      <div className="timeline-container">
        {filteredList?.map(({ _id: id, date: journalDate, content }, i) => {
          const [weekday, month, date, year] = new Date(journalDate.slice(0, 10))
            .toDateString()
            .split(' ');
          const [, prevMonth = ''] =
            (i > 0 &&
              new Date(filteredList[i - 1]['date'].slice(0, 10)).toDateString().split(' ')) ||
            [];

          let contentState;
          try {
            contentState = convertFromRaw(JSON.parse(content));
          } catch (error) {
            contentState = ContentState.createFromText(content);
          }
          return (
            <div key={id} ref={i == filteredList.length - 1 ? lastElementRef : null}>
              {prevMonth != month && (
                <div className="journal-month-header"> {`${month}, ${year}`}</div>
              )}
              <fieldset
                className="journal-card"
                onClick={() => {
                  navigate('/journal', { state: { id } });
                }}>
                <legend className="journal-date-header"> {`${date} ${month}, ${weekday}`}</legend>
                <pre>
                  <Editor
                    readOnly
                    wrapperStyle={{ height: '50px' }}
                    editorState={
                      content
                        ? EditorState.createWithContent(contentState)
                        : EditorState.createEmpty()
                    }
                  />
                </pre>
              </fieldset>
            </div>
          );
        })}
      </div>
      {(loading || loading2) && (
        <div style={{ textAlign: 'center' }}>
          <BeatLoader size={20} color="var(--bg-accent)" loading={true} />
        </div>
      )}
    </div>
  );
};

export default Timeline;
