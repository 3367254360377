// import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import './style.css';

const RichEditor = ({ setEditorState, editorState }) => {
  const onEditorStateChange = (state) => setEditorState(state);
  return (
    <div className="" style={{ height: '100vh', position: 'relative' }}>
      <Editor
        // toolbarOnFocus
        wrapperStyle={{ height: '100%' }}
        editorClassName="editor"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbar"
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            //TODO: remove unlink from link
            'history'
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { options: ['link'] },
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code']
          },
          fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36] },
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana',
              'Kalam',
              'Roboto'
            ]
          }
        }}
      />
    </div>
  );
};

export default RichEditor;
