function generateRandomCode(length = 5, charset = 'abcdefghijklmnopqrstuv01123456789') {
  let code = '';
  for (let i = 0; i < length; i++) {
    code += charset[Math.floor(Math.random() * charset.length)];
  }
  return code;
}

const insertTextAtCursor = (ref, originalText = '', textToInsert) => {
  if (!ref || !ref.current || !textToInsert) {
    return;
  }
  const cursorStart = ref.current.selectionStart;
  const cursorEnd = ref.current.selectionEnd;
  const preCursorText = originalText.substring(0, cursorStart);
  const postCursorText = originalText.substring(cursorEnd);
  return preCursorText + textToInsert + postCursorText;
};
export { generateRandomCode, insertTextAtCursor };
