import { create } from 'zustand';
import axios from '../lib/axios';
import { binarySearch } from '../lib/algorithm';

const tagsStore = (set, get) => ({
  tags: [],
  fetchTags: async () => {
    const { data } = await axios.get('tags/');
    const { tags } = data;
    set({ tags });
  },
  reset: () => {
    set({ tags: [] });
  },
  addJournalToTag: async (jid, tagId, key = '', value = 0) => {
    await axios.post('journal/tag/add-tag', {
      journalId: jid,
      tagId: tagId,
      key,
      value
    });
  },

  removeJournalFromTag: (jid, tag) =>
    set((state) => {
      const journalArray = state.tags[tag] || [];
      const index = binarySearch(
        journalArray.map((j) => j[0]),
        jid
      );
      if (journalArray[index][0] === jid) {
        journalArray.splice(index, 1);
      }
      return state;
    }),
  getJournalIdsByTag: (searchKey) => {
    const tags = get().tags;
    return tags.find((tag) => tag.key === searchKey);
  }
});

// export default create(
//   persist(tagsStore, {
//     name: 'tags'
//   })
// );
export default create(tagsStore);
/**
 *  State will be an object with key = tag name (unique) and value is array of journal Ids
 *  in reverse sorted order
 *
 * tags: {
 *  1:
 *  2:
 *  3:
 *  4:
 *  5:
 *  5:
 *  6:
 *  7: [1,2,3]
 *  8:
 *  9: [1,2,3]
 * }
 */
