import React from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickOutside } from '../../../lib/custom-hooks';
import useJournalStore from '../../../state/journals';
import './style.css';
import Button from '../../../component/Button';

const ConfirmDeletePopup = ({ setShowDeletePopup, id }) => {
  const deleteJournal = useJournalStore((state) => state.deleteJournal);

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    setShowDeletePopup(false);
  });

  const navigate = useNavigate(null);

  return (
    <div className="blur">
      <div ref={popupRef} className="delete-popup-container popup">
        <p className="title"> Are you sure?</p>
        <div className="popup-btn-group">
          <button
            style={{ backgroundColor: 'var(--accent-green)', color: 'var(--font-accent-green)' }}
            onClick={() => setShowDeletePopup(false)}>
            Cancel
          </button>
          <Button
            style={{ backgroundColor: 'var(--accent-red)', color: 'var(--font-accent-red)' }}
            onClick={async () => {
              // localStorage.removeItem(id);
              // tags.forEach((tag) => removeJournalFromTag(id, tag));
              await deleteJournal(id);
              navigate(-1);
            }}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
