import React, { useRef } from 'react';
import { useClickOutside } from '../../../../lib/custom-hooks';

const FilterPopup = ({
  tags,
  searchTag,
  setSearchTag,
  searchMonthYear,
  setSearchMonthYear,
  setShowFilterPopup
}) => {
  const popupRef = useRef(null);
  useClickOutside(popupRef, () => setShowFilterPopup(false));

  return (
    <div ref={popupRef} className="filter-container ">
      <label>
        <span>Tag</span>
        <select
          onChange={(e) => {
            setSearchTag(e.target.value);
          }}
          value={searchTag}>
          <option value=""> select</option>
          {tags.map((tag) => (
            <option key={tag._id} value={tag.key}>
              {tag.key}
            </option>
          ))}
        </select>
      </label>
      <label>
        <span>Month</span>
        <input
          type="month"
          id="myMonthInput"
          name="myMonthInput"
          value={searchMonthYear}
          onChange={(e) => {
            setSearchMonthYear(e.target.value);
          }}
        />
      </label>
    </div>
  );
};

export default FilterPopup;
