import React, { useState } from 'react';
import './style.css';
import useTemplateStore from '../../../state/template';
import { FaChevronLeft } from 'react-icons/fa';

const AddPrompt = ({ setShowAddPromptPopup }) => {
  const [title, setTitle] = useState('');
  const [promptText, setPromptText] = useState('');

  const addTemplate = useTemplateStore((state) => state.addTemplate);

  const handleSubmit = (e) => {
    e.preventDefault();
    const titleInput = title.trim();
    const promptTextInput = promptText.trim();
    if (!titleInput || !promptTextInput) {
      return;
    }
    titleInput.split(' ').filter(Boolean).join('-');
    addTemplate(titleInput, promptTextInput);
    setShowAddPromptPopup(false);
  };
  return (
    <div className="blur">
      <div className="add-prompt-container popup">
        <FaChevronLeft
          style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '20px' }}
          onClick={() => {
            setShowAddPromptPopup(false);
          }}
        />
        <form onSubmit={handleSubmit}>
          <label htmlFor="prompt-title">Title</label>
          <input
            type="text"
            id="prompt-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            pattern="[a-zA-Z\s]{5,30}"
            required
            maxLength="30"
          />
          <label htmlFor="prompt-text">Prompt</label>
          <textarea
            id="prompt-text"
            value={promptText}
            onChange={(e) => setPromptText(e.target.value)}
            required
          />
          <button type="submit">Save</button>
        </form>
      </div>
    </div>
  );
};

export default AddPrompt;
