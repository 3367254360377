import { create } from 'zustand';
import axios from '../lib/axios';
import useTagStore from './tags';
import {
  decryptDataWithKey,
  deriveEncryptionKeyFromPassword,
  encryptDataWithKey,
  generateRandomEncryptionKey,
  generateRandomSalt
} from '../lib/encryption';
import { enc } from 'crypto-js';

async function getDataKey() {
  let dataKey;
  const derivedKeyStringified = sessionStorage.getItem('derivedKey') || '';

  if (derivedKeyStringified) {
    const derivedKey = enc.Base64.parse(derivedKeyStringified);
    const res = await axios.get('user/data-Key');
    const { encryptedDataKey } = JSON.parse(res?.data?.encryptionData || null) || {};
    dataKey = decryptDataWithKey(encryptedDataKey, derivedKey);
  } else {
    const res = await axios.get('user/biometric-data-Key');
    const derivedKeyAlt = enc.Base64.parse(sessionStorage.getItem('derivedKeyAlt') || '');

    const { encryptedDataKey } = JSON.parse(res?.data?.encryptionData || null) || {};
    dataKey = decryptDataWithKey(encryptedDataKey, derivedKeyAlt);
  }

  return dataKey;
}

const journalStore = (set, get) => ({
  page: 1,
  setPage: (value) => {
    set({ page: value });
  },
  journals: [],
  hasMore: false,
  fetch: async (limit = 10, skip = 0) => {
    const { data } = await axios.get(`journal/list?limit=${limit}&skip=${skip}`);
    const { collection: journals } = data;
    const dataKey = await getDataKey();

    const decryptedJournals = journals.map((j) => ({
      ...j,
      content: decryptDataWithKey(j.content, dataKey) || j.content
    }));

    const existingJournals = new Set(get().journals.map((j) => j._id));
    const filteredJournals = decryptedJournals.filter((j) => !existingJournals.has(j._id));
    set({
      journals: [...get().journals, ...filteredJournals],
      hasMore: decryptedJournals.length > 0
    });
  },
  setJournals: (journals) => {
    set({ journals });
  },
  reset: () => {
    sessionStorage.removeItem('journals');
    set({ journals: [] });
  },
  upsertJounal: async ({ id, content, date, title = '', isEncrypted = false }) => {
    if (!content || !date) return;
    let encryptedContent = content;

    if (!isEncrypted) {
      const dataKey = await getDataKey();
      encryptedContent = encryptDataWithKey(content, dataKey);
    }

    const { data } = await axios.post('journal/upsert', {
      id,
      content: encryptedContent,
      date,
      title
    });
    //fetch all journals to make state consistent
    //solves the issue of, unable to fetch the journal after saving using getJournalById because
    // the journal list is not updated
    // this will make sure list is updated after save and getJournalById returns correct journal
    const journal = data.journal;
    journal.content = content;
    if (!id) {
      get().fetch();
    } else {
      //update the state with recent data, to prevent datat loss
      set((state) => ({
        journals: state.journals.map((j) => (j._id === id ? { ...j, ...journal } : j))
      }));
    }
    return data.journal;
  },
  addTagToJournal: async (id, tagId, key, value) => {
    const { data } = await axios.post('journal/tag/add-tag', {
      journalId: id,
      tagId: tagId,
      key,
      value
    });
    return data.tag;
  },
  deleteTagFromJournal: async (journalId, tagId) => {
    const { data } = await axios.post('journal/tag/remove-tag', {
      tagId,
      journalId
    });
    return data.tag;
  },
  deleteJournal: async (id) => {
    await axios.post('journal/delete', { id });
    await Promise.all([get().fetch(), useTagStore.getState().fetchTags()]);
  },
  getJournalById: async (id) => {
    const dataKey = await getDataKey();
    const { data } = await axios.get(`journal/${id}`);
    const journal = data?.journal;
    return { ...journal, content: decryptDataWithKey(journal.content, dataKey) || journal.content };
  },
  getAllJournals: () => get().journals,

  encryptJournalsWithNewKey: async (id, password) => {
    const salt = generateRandomSalt();
    const newDerivedKey = deriveEncryptionKeyFromPassword(password, salt, 200000, 16); //to be saved in session storage
    const res = await axios.get('user/data-Key');
    const encryptionDataStringified = res?.data?.encryptionData;
    let newEncryptedDataKey;
    if (!encryptionDataStringified) {
      sessionStorage.setItem('derivedKey', enc.Base64.stringify(newDerivedKey));
      const newDataKey = generateRandomEncryptionKey();
      newEncryptedDataKey = encryptDataWithKey(newDataKey, newDerivedKey);

      get().journals.forEach((j) =>
        get().upsertJounal({
          ...j,
          id: j._id,
          content: encryptDataWithKey(j.content, newDataKey),
          isEncrypted: true
        })
      );
    } else {
      const dataKey = await getDataKey();
      newEncryptedDataKey = encryptDataWithKey(dataKey, newDerivedKey);
    }

    const encryptionData = {
      encryptedDataKey: newEncryptedDataKey,
      length: 16,
      iterations: 200000,
      salt
    };
    await axios.post('user/reset-password', {
      id,
      password,
      encryptionData: JSON.stringify(encryptionData)
    });
  }
});

const useJournalStore = create(journalStore);

export default useJournalStore;

export const PAGE_SIZE = 10;
