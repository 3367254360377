import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useConfigStore from '../../state/config';
import Login from '../Authentication/login';
import Register from '../Authentication/register';
import useJournalStore from '../../state/journals';
import useTagStore from '../../state/tags';
import useTemplateStore from '../../state/template';

const PrivateRoute = ({ children }) => {
  const [showDemo] = useConfigStore((state) => [state.showDemo]);
  const user = useConfigStore((state) => state.user);
  const token = sessionStorage.getItem('accessToken');
  const [isLogin, setIsLogin] = useState(true);
  if (showDemo) {
    return <Navigate to="about" />;
  }

  if (!token) {
    useJournalStore.getState().reset();
    useTemplateStore.getState().reset();
    useTagStore.getState().reset();
    user?.id && useConfigStore.getState().reset();
    sessionStorage.removeItem('derivedKey');
    sessionStorage.removeItem('derivedKeyAlt');
    return isLogin ? <Login setIsLogin={setIsLogin} /> : <Register setIsLogin={setIsLogin} />;
  }
  return children;
};

export default PrivateRoute;
