import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import useConfigStore from '../../../../state/config';
import useJournalStore from '../../../../state/journals';
import '../style.css';
import { useNavigate } from 'react-router-dom';

const NewPassword = ({ isLoggedIn = true }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  // Password updated successfully
  const [loading, setLoading] = useState(false);
  const getUser = useConfigStore((state) => state.getUser);
  const encryptJournalsWithNewKey = useJournalStore((state) => state.encryptJournalsWithNewKey);
  const id = getUser().id;

  const navigate = useNavigate();
  const resetForm = () => {
    setPassword('');
    setConfirmPassword('');
  };

  function validateForm() {
    setErrors([]);
    let isValid = true;
    const validPasswordPattern = password.match(/^[a-zA-Z0-9_#@-]{8,20}$/);

    if (!password || !confirmPassword) {
      setErrors((e) => Array.from(new Set([...e, 'all fields are required'])));
      isValid = false;
    }

    if (!validPasswordPattern) {
      setErrors((e) => Array.from(new Set([...e, 'invalid password'])));
      isValid = false;
      return false;
    }

    if (password !== confirmPassword) {
      setErrors((e) => Array.from(new Set([...e, 'password must match'])));
      isValid = false;
    }
    return isValid;
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      await encryptJournalsWithNewKey(id, password);

      sessionStorage.removeItem('accessToken');
      setSuccessMessage('Password updated successfully!');
      setTimeout(() => {
        setLoading(false);
        navigate('/about');
      }, 1300);
    } catch (e) {
      let message = e?.response?.data?.message;

      if (!message) {
        message = e.message;
      }

      setErrors((err) => Array.from(new Set([...err, message])));
    } finally {
      if (!isLoggedIn) {
        setLoading(false);
        navigate('/logout');
      }
      resetForm();
    }
  };

  return (
    <div>
      <p style={{ color: 'var(--accent-green)', textAlign: 'center' }}>{successMessage}</p>
      <form className="password-form" onSubmit={handleFormSubmit}>
        <input
          type="password"
          placeholder="enter new password"
          required
          value={password}
          onChange={(e) => {
            setErrors([]);
            setPassword(e.target.value);
          }}
        />
        <span className="input-hint">
          allowed characters are alphanumerics and (_, #, @, -)
          <br />
          min length of 8 and max of 20 characters
        </span>

        <input
          type="password"
          placeholder="confirm password"
          required
          value={confirmPassword}
          onChange={(e) => {
            setErrors([]);
            setConfirmPassword(e.target.value);
          }}
        />
        <div style={{ position: 'relative', color: 'var(--accent-red)' }}>
          {errors?.map((err, i) => (
            <span key={i}>{err}</span>
          ))}
        </div>

        {loading ? (
          <ClipLoader
            cssOverride={{ margin: 'auto' }}
            color="var(--bg-accent-secondary)"
            loading={loading}
          />
        ) : (
          <button
            type="submit"
            style={{
              backgroundColor: 'var(--accent-green)',
              color: 'var(--font-accent-green)'
            }}>
            Confirm
          </button>
        )}
      </form>
    </div>
  );
};

export default NewPassword;
