import React from 'react';
import Dashboard from './dashboard';
import './style.css';
import Timeline from './timeline';

const Home = () => {
  return (
    <div className="home-container">
      <Dashboard />

      <Timeline />
    </div>
  );
};

export default Home;
