import React, { useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { VscTriangleDown } from 'react-icons/vsc';
import { useClickOutside } from '../../../lib/custom-hooks';
import './style.css';
import useJournalStore from '../../../state/journals';
import Button from '../../../component/Button';

const TagPopup = ({ setShowTagPopup, jid, tags, setTags }) => {
  const promptRef = useRef(null);

  const [showSuggestions, setShowSuggestions] = useState(false);

  const addTagToJournal = useJournalStore((state) => state.addTagToJournal);
  const deleteTagFromJournal = useJournalStore((state) => state.deleteTagFromJournal);

  // const removeJournalFromTag = useTagStore((state) => state.removeJournalFromTag);

  const [tagHover, setTagHover] = useState('');
  // const journal = getJournalById(jid);

  // const [tagList, setTagList] = useState(journal?.tags || []);
  const tagList = tags;
  useClickOutside(promptRef, () => {
    setShowTagPopup(false);
  });

  const [tag, setTag] = useState('');
  const [tagId, setTagId] = useState('');

  const [tagValue, setTagValue] = useState('');

  if (!jid) {
    return (
      <div className="blur">
        <div ref={promptRef} className="tag-container popup">
          <div style={{ textAlign: 'center', color: 'red' }}>
            <span> please save the journal first</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="blur">
      <div ref={promptRef} className="tag-container popup">
        <div className="cancel-icon" onClick={() => setShowTagPopup(false)}>
          <FaTimes />
        </div>
        <div className="add-tag-form">
          <div className="add-tag">
            <label style={{ position: 'relative' }}>
              Tag
              <VscTriangleDown
                onClick={() => {
                  setShowSuggestions(!showSuggestions);
                }}
                style={{
                  position: 'absolute',
                  right: '5px',
                  fontSize: '1.3rem'
                }}
              />
              <input
                type="text"
                placeholder="name, ex- running"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
              {showSuggestions && (
                <div className="dropdown">
                  {tags
                    .filter((t) => {
                      const rgx = new RegExp(tag, 'g');
                      return showSuggestions && t.key.match(rgx);
                    })
                    .map((t, i) => (
                      <div
                        className="dropdown-row"
                        key={t._id}
                        onClick={() => {
                          setTagId(t.id);
                          setTag(t.key);
                          setShowSuggestions(false);
                        }}>
                        {t.key}
                        {i < tags.length - 1 && <hr className="divider" />}
                      </div>
                    ))}
                </div>
              )}
            </label>
            <label>
              Value
              <input
                type="number"
                placeholder="value, ex- 5"
                value={tagValue}
                onChange={(e) => setTagValue(e.target.value)}
              />
            </label>
          </div>

          <Button
            onClick={async () => {
              const tagKey = tag.trim();
              const value = parseInt(tagValue.trim());
              if (tagKey === '' || !jid || value === undefined || value === null || isNaN(value)) {
                return;
              }

              const addedTag = await addTagToJournal(jid, tagId, tagKey, value);
              setTags((tagsList) => [...tagsList, addedTag]);
              setTag('');
              setTagValue('');
              setTagId(''); //causing the old tagId to be sent, that was not letting create new tags
            }}
            style={{ backgroundColor: 'var(--accent-green)', color: 'var(--font-accent-green)' }}>
            Add
          </Button>
        </div>
        {Boolean(tagList?.length) && (
          <div className="tags" style={{ backgroundColor: 'var(--bg-primary)' }}>
            {tagList.map((t) => (
              <span
                className="tag"
                key={t.id}
                onMouseEnter={() => setTagHover(t.key)}
                onMouseLeave={() => setTagHover('')}>
                {t.key}
                <FaTimes
                  style={{ transform: tagHover === t.key && 'translate(-50%,-50%)' }}
                  onClick={async () => {
                    const deletedJournalTag = await deleteTagFromJournal(jid, t.id);
                    setTags((ts) =>
                      ts.filter((filterTag) => filterTag.id !== deletedJournalTag.tag)
                    );
                    // removeJournalFromTag(id, t.key);
                  }}
                />
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagPopup;
