import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initState = {
  theme: '',
  pin: '',
  user: {},
  isSidebarOpen: false,
  showDemo: true,
  fonts: [
    { name: 'Kalam', value: 'Kalam' },
    { name: 'Merriweather', value: 'Merriweather' },
    { name: 'Montserrat', value: 'Montserrat' },
    { name: 'Roboto', value: 'Roboto' },
    { name: 'Sans', value: 'Source Sans Pro' }
  ],
  fontSize: '22',
  fontFamily: ''
};

const configStore = (set, get) => ({
  ...initState,
  reset: () => {
    localStorage.removeItem('config');
    set({ ...initState, showDemo: false });
  },
  setUser: (user) => set(() => ({ user })),
  getUser: () => get().user,
  setSidebar: (status) => set(() => ({ isSidebarOpen: status })),
  setTheme: (theme) => set(() => ({ theme: theme })),
  setPIN: (pin) => set(() => ({ pin: pin })),
  setFontSize: (size) => set(() => ({ fontSize: size })),
  setFontFamily: (font) => set(() => ({ fontFamily: font })),
  setShowDemo: (status) => set(() => ({ showDemo: status })),
  getSidebar: () => get().isSidebarOpen
});

export default create(
  persist(configStore, {
    name: 'config',
    storage: createJSONStorage(() => sessionStorage)
  })
);
