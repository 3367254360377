import { useEffect, useState } from 'react';
import useJournalStore, { PAGE_SIZE } from '../state/journals';

const useDelayUnmount = (isNavOpened, delay) => {
  // false ,10sec
  const [shouldRender, setShouldRender] = useState(false);
  false;
  let timeoutId;
  useEffect(() => {
    if (isNavOpened && !shouldRender) {
      setShouldRender(true);
    } else if (!isNavOpened && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delay);
    }
    return () => clearTimeout(timeoutId);
  }, [isNavOpened, setShouldRender, delay]);
  return shouldRender;
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const useClickOutside = (ref, func) => {
  useEffect(() => {
    const listener = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      func();
    };
    document.addEventListener('mousedown', listener);
    // document.addEventListener('touchstart', listener);
    // document.addEventListener('touchend', listener);
    // document.addEventListener('touchmove', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      // document.removeEventListener('touchstart', listener);
      // document.removeEventListener('touchend', listener);
      // document.removeEventListener('touchmove', listener);
    };
  }, [ref]);
};

function useAutosaveData(callback, delay = 1000, deps) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callback();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, deps);
}

function useJournalData() {
  const [loading, setLoading] = useState(true);
  const fetch = useJournalStore((state) => state.fetch);
  const page = useJournalStore((state) => state.page);
  const getJournals = async () => {
    setLoading(true);
    try {
      await fetch(PAGE_SIZE, (page - 1) * PAGE_SIZE);
    } catch (error) {
      console.log('error in fetching journal data', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log('calling fetch journal data', page);
    getJournals();
  }, [page]);

  return { loading };
}
export { useWindowSize, useClickOutside, useAutosaveData, useDelayUnmount, useJournalData };
