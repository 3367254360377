import React, { useRef, useState } from 'react';
import '../style.css';
import { useClickOutside } from '../../../../lib/custom-hooks';
import { useNavigate } from 'react-router-dom';
import NewPassword from './new-password';
import OldPassword from './old-password';

const ResetPasswordPopup = () => {
  const popupRef = useRef(null);
  const navigate = useNavigate();
  useClickOutside(popupRef, () => navigate(-1));

  const [step2, setStep2] = useState(false);
  // const [setkey, key] = useConfigStore((state) => [state.setkey, state.key]);

  return (
    <div className="blur">
      <div ref={popupRef} className="popup set-password-container">
        <h2 className="title"> Reset Password</h2>
        {step2 ? <NewPassword /> : <OldPassword setStep2={setStep2} />}
      </div>
    </div>
  );
};

export default ResetPasswordPopup;
