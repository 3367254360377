import React from 'react';
import './style.css';
import useJournalStore from '../../../state/journals';
import { toJournalDateFormat, decreaseDateByDays, WEEKDAYS } from '../../../lib/dates';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
  VictoryVoronoiContainer
  // VictoryVoronoiContainer
} from 'victory';
import { useNavigate } from 'react-router-dom';

function getStats(journals = []) {
  const stats = {
    totalEntries: journals.length,
    streak: 0,
    longestStreak: 0,
    distributionByWeekday: Object.fromEntries(WEEKDAYS.map((day) => [day, 0])),
    distributionByHour: Object.fromEntries([...Array(24)].map((_, i) => [i + 1, 0])),
    mostFrequentHour: 12
  };
  let lastDate = toJournalDateFormat();
  let countingStreak = true;
  let streak = 0;

  const updateStreakStats = (journalDate) => {
    if (journalDate > lastDate) return;
    if (lastDate === journalDate) {
      streak += 1;
    } else if (journalDate < lastDate) {
      countingStreak = false;
      streak = 1;
      lastDate = journalDate;
    }
    if (countingStreak) {
      stats.streak = streak;
    }
    stats.longestStreak = Math.max(streak, stats.longestStreak);
    lastDate = decreaseDateByDays(lastDate, 1); //decrease last day by 1 day
  };

  const updateWeekdayStats = (journalDate) => {
    const day = WEEKDAYS[new Date(journalDate).getDay()];
    stats.distributionByWeekday[day] += 1;
  };

  const updateHourStats = (hour) => {
    stats.distributionByHour[hour] += 1;

    if (stats.distributionByHour[hour] > stats.distributionByHour[stats.mostFrequentHour]) {
      stats.mostFrequentHour = hour;
    }
  };
  for (const journal of journals) {
    const journalDate = journal.date;
    const hour = new Date(journal['created_at']).getHours();
    // console.log(journalDate);
    //update entry stats such as longestStreak, streak
    updateStreakStats(journalDate);
    ///update entry stats by weekdays
    updateWeekdayStats(journalDate);

    updateHourStats(hour);
  }

  return stats;
}
const Dashboard = () => {
  // const now = new Date().toTimeString().slice(0, 8);
  const journals = useJournalStore((state) => state.journals);
  const stats = getStats(journals.sort((a, b) => new Date(b.date) - new Date(a.date)));
  const array1To24 = [...Array(24)].map((_, i) => i + 1);
  const weekData = Object.entries(stats.distributionByWeekday).map((data) => ({
    day: data[0],
    entries: data[1]
  }));
  const hourData = Object.entries(stats.distributionByHour).map((data) => ({
    hour: data[0],
    entries: data[1]
  }));

  const navigate = useNavigate();
  return (
    <div className="dashboard-container">
      <button
        onClick={() => {
          navigate('journal');
        }}>
        write your story
      </button>

      <div className="card-container">
        <div className="">
          <div className="card">Stories: {stats.totalEntries}</div>
          <div className="card">Longest Streak: {stats.longestStreak}</div>
          <div className="card">Streak: {stats.streak}</div>
        </div>
        <div className="charts">
          <div className="card">
            <VictoryChart
              padding={{ bottom: 20, left: 45, top: 20 }}
              // margin={{ top: 50, bottom: 0, right: 0, left: 0 }}
              domainPadding={30}>
              <VictoryAxis
                tickValues={['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']}
                tickFormat={['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']}
                style={{
                  tickLabels: { fontSize: 23, fontWeight: 500, fill: 'var(--font-secondary)' }
                }}
              />

              <VictoryLabel
                style={{
                  fill: 'var(--font-secondary)',
                  fontSize: 35
                }}
                x={225}
                text="Weekly"
                textAnchor="middle"
              />
              <VictoryAxis
                style={{
                  grid: { stroke: 'var(--shadow-primary)', opacity: 0.5 },
                  tickLabels: { fontSize: 23, fontWeight: 500, fill: 'var(--font-secondary)' }
                }}
                tickFormat={(t) => t}
                dependentAxis
              />

              <VictoryLine
                interpolation="monotoneX"
                style={{
                  data: {
                    // fill: 'var(--bg-primary)',
                    stroke: 'var(--font-secondary)',
                    strokeWidth: 1
                  }
                }}
                data={weekData}
                x="day"
                y="entries"
                animate={{
                  duration: 1000,
                  easing: 'polyOut'
                }}
              />
            </VictoryChart>
          </div>

          <div className="card">
            <VictoryChart
              padding={{ bottom: 45, left: 15, top: 30 }}
              margin={{ top: 0, bottom: 0, right: 0, left: 0 }}
              domainPadding={30}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={(d) => {
                    const hr = d.datum.hour;
                    return `${hr > 12 ? hr - 12 + ' PM' : hr + 'AM'}`;
                  }}
                />
              }>
              <VictoryLabel
                style={{
                  fill: 'var(--font-secondary)',
                  fontSize: 35
                }}
                x={225}
                y={25}
                text="Hourly"
                textAnchor="middle"
              />
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 17, fontWeight: 500, fill: 'var(--font-secondary)' }
                }}
                tickValues={array1To24}
                tickFormat={array1To24?.map((h) => {
                  if (h % 3 == 0) return h > 12 ? h - 12 + '\nPM' : h + '\nAM';
                })}
                tickFormat={(t) => {
                  if (t % 3 !== 0) return '';
                  return t > 12 ? t - 12 + '\nPM' : t + '\nAM';
                }}
              />
              <VictoryAxis
                style={{
                  grid: { stroke: 'var(--shadow-primary)', opacity: 0.5 },

                  tickLabels: { fontSize: 20, fontWeight: 500, fill: 'var(--font-secondary)' }
                }}
                tickFormat={(t) => t}
                dependentAxis
              />

              <VictoryLine
                interpolation="monotoneX"
                style={{
                  data: {
                    stroke: 'var(--font-secondary)',
                    width: '20',
                    strokeWidth: 1
                  }
                }}
                data={hourData}
                x="hour"
                y="entries"
                animate={{
                  duration: 1000,
                  easing: 'polyOut'
                }}
              />
            </VictoryChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
/**
 * what to add to the dashboard ?
 * latest journaling streak
 * total entries
 * githib like entries board ?
 *
 */
