import React, { useState } from 'react';
import axios from '../../../../lib/axios';
import useConfigStore from '../../../../state/config';
import Button from '../../../../component/Button';
import { startAuthentication } from '@simplewebauthn/browser';

const OldPassword = ({ setStep2 }) => {
  const [error, setError] = useState('');

  const getUser = useConfigStore((state) => state.getUser);

  const username = getUser().username;

  const biometricHandler = async () => {
    try {
      const res = await axios.get(`auth/authentication/options/${username}`);
      const { options } = res.data;

      const authenticationRes = await startAuthentication(options);
      const verificationResp = await axios.post('auth/authentication/verify', {
        response: authenticationRes,
        username: username,
        credentialID: authenticationRes.id
      });
      const { verified, user, access_token: accessToken } = verificationResp.data;
      if (!user || !accessToken) {
        throw new Error('User not found');
      }
      if (verified) {
        setStep2(true);
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        {error && <span className="error-message">{error}</span>}
      </div>
      <Button
        style={{
          backgroundColor: 'var(--accent-green)',
          color: 'var(--font-accent-green)',
          display: 'flex'
        }}
        onClick={biometricHandler}>
        Use Biometrics
      </Button>
    </div>
  );
};

export default OldPassword;
