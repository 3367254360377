const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

function toJournalDateFormat(date = '') {
  const updatedDate = date || new Date();
  // const options = {
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  //   hour12: false
  // };

  const [day, month, year] = updatedDate.toLocaleDateString().split('/');

  // console.log([y, m, d].join('-') + 'T' + time);
  return [year, month, day].join('-');
}

function decreaseDateByDays(date, days) {
  const tempDate = new Date(date);
  tempDate.setDate(tempDate.getDate() - days);
  return toJournalDateFormat(tempDate);
}

export { WEEKDAYS, toJournalDateFormat, decreaseDateByDays };
