import React from 'react';
import './style.css';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { useState } from 'react';
import FilterPopup from './filter-popup';

const SearchFilter = ({
  searchText,
  setSearchText,
  tags,
  searchTag,
  setSearchTag,
  searchMonthYear,
  setSearchMonthYear
}) => {
  const [showFilterPopup, setShowFilterPopup] = useState();

  return (
    <div className="search-filter-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="search"
          className="search-input"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <FaSearch className="search-icon" />
      </div>
      <div style={{ position: 'relative' }}>
        <FaFilter className="filter-icon" onClick={() => setShowFilterPopup(!showFilterPopup)} />
        {showFilterPopup && (
          <FilterPopup
            tags={tags}
            searchTag={searchTag}
            setSearchTag={setSearchTag}
            searchMonthYear={searchMonthYear}
            setSearchMonthYear={setSearchMonthYear}
            setShowFilterPopup={setShowFilterPopup}
          />
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
/*

*/
