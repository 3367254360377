import React, { useState } from 'react';
import '../style.css';
import axios from '../../../lib/axios';
import { ClipLoader } from 'react-spinners';
import useConfigStore from '../../../state/config';
import { useNavigate } from 'react-router-dom';
import { generateRandomEncryptionKey, generateEncryptionData } from '../../../lib/encryption';
import { enc } from 'crypto-js';

const Register = ({ setIsLogin }) => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState('');
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const navigate = useNavigate();
  const setUser = useConfigStore((state) => state.setUser);
  function resetForm() {
    setPassword('');
    setConfirmPassword('');
    setUsername('');
    setName('');
    setIsValidUsername(true);
  }

  function validateForm() {
    setErrors([]);
    let isValid = true;
    const validUsernamePattern = username.match(/^[a-zA-Z][a-zA-Z0-9]{4,}$/);
    const validPasswordPattern = password.match(/^[a-zA-Z0-9_#@-]{8,20}$/);

    if (!username || !password || !confirmPassword) {
      setErrors((e) => Array.from(new Set([...e, 'all fields are required'])));
      isValid = false;
    }

    if (!validUsernamePattern || !isValidUsername) {
      setErrors((e) => Array.from(new Set([...e, 'invalid username'])));
      isValid = false;
    }
    if (!validPasswordPattern) {
      setErrors((e) => Array.from(new Set([...e, 'invalid password'])));
      isValid = false;
      return false;
    }

    if (password !== confirmPassword) {
      setErrors((e) => Array.from(new Set([...e, 'password must match'])));
      isValid = false;
    }
    return isValid;
  }
  const formHandler = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const dataKey = generateRandomEncryptionKey();
      const { derivedKey, ...encryptionData } = generateEncryptionData(dataKey, password);
      const res = await axios.post('user/register', {
        name,
        username,
        password,
        encryptionData: JSON.stringify(encryptionData)
      });
      const { user, access_token } = res.data;
      if (!user || !access_token) {
        throw new Error('User not found');
      }

      setShowWelcomeMessage(true);
      setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem('accessToken', access_token);
        sessionStorage.setItem('derivedKey', enc.Base64.stringify(derivedKey));
        setUser(user);
        navigate('settings/privacy/fingerprint');
      }, 1200);
    } catch (error) {
      setErrors((e) => Array.from(new Set([...e, error.message])));
      setLoading(false);
    }
    resetForm();
  };

  return (
    <div className="blur">
      <div className="popup register-container">
        {showWelcomeMessage && <p> Welcome {name}, you're successfully registered</p>}
        <form onSubmit={formHandler}>
          <input
            required
            placeholder="Display Name"
            autoFocus
            value={name}
            onChange={(e) => {
              setErrors([]);
              setName(e.target.value);
            }}
          />
          <div>
            <input
              id="username"
              required
              placeholder="username"
              autoFocus
              value={username}
              onBlur={async () => {
                if (!username) {
                  return;
                }
                try {
                  const res = await axios.get(`user/exists/${username}`);

                  setIsValidUsername(!res?.data?.isExist);
                } catch (error) {
                  setErrors((e) => Array.from(new Set([...e, error.message])));
                }
              }}
              onChange={(e) => {
                setErrors([]);
                setIsValidUsername(true);
                setUsername(e.target.value);
              }}
            />

            {!isValidUsername && username && (
              <>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    marginBottom: '5px',
                    marginTop: '0px',
                    color: 'var(--accent-red)'
                  }}>
                  Username is already taken
                </span>
                <br />
              </>
            )}
            <span className="input-hint">
              only alphanumeric characters are allowed
              <br />
              min length of 5 characters
            </span>
          </div>

          <div>
            <input
              type="password"
              placeholder="password"
              required
              value={password}
              onChange={(e) => {
                setErrors([]);
                setPassword(e.target.value);
              }}
            />
            <span className="input-hint">
              allowed characters are alphanumerics and _ , # , @ , -
              <br />
              min length of 8 and max of 20 characters
            </span>
          </div>

          <input
            type="password"
            placeholder="confirm password"
            required
            value={confirmPassword}
            onChange={(e) => {
              setErrors([]);
              setConfirmPassword(e.target.value);
            }}
          />
          {errors?.map((e, i) => (
            <span key={i}>{e}</span>
          ))}
          {loading ? (
            <ClipLoader
              cssOverride={{ margin: 'auto' }}
              color="var(--bg-accent-secondary)"
              loading={loading}
            />
          ) : (
            <button disabled={loading} type="submit">
              Register
            </button>
          )}

          <hr className="divider" />
          {!loading && (
            <span
              className="secondary-submit"
              onClick={() => {
                setIsLogin(true);
              }}>
              Login
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;
/*

generate the random key1 (will remain constant lifetime , persisted in db after encryption )
generate password derived key2
ecrypt Key1 with key2 => enc_key#1
save the enc_key#1 in the db API call along with
  iterations, salt and length of the enc_key#1
save the derived key #2 in the session storage during logn and registration
To list journals
i'll fetch the ecryptionData from DB, use session storage Key#2 to decrypt enc_key#1 from encryptionData ,
    then use Key#1, to decrypt the journals
To upsert a joournal
i'll fetch the ecryptionData, use session storage Key#2 to decrypt enc_key#1 from encryptionData ,
then use Key#1 to encrypt the journal and call upsert API.
      */
