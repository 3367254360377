function binarySearch(list = [], target, comp = (a, b) => (a === b ? 0 : a > b ? 1 : -1)) {
  if (!list.length && !target) return;
  if (!list.length) return 0;
  let low = 0;
  let high = list.length - 1;
  let mid;
  while (low <= high) {
    mid = Math.floor((low + high) / 2);
    const res = comp(list[mid], target);
    if (res === 0) {
      return mid;
    } else if (res > 0) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }

    // if (list[mid].id === target) {
    //   return mid;
    // } else if (list[mid].id > target) {
    //   low = mid + 1;
    // } else {
    //   high = mid - 1;
    // }
  }
  return low;
}

export { binarySearch };
