import Axios from 'axios';
const { REACT_APP_BACKEND_URL } = process.env;

const axios = Axios.create({
  baseURL: REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('accessToken');
  config.headers.authorization = token;
  return config;
});

export default axios;
