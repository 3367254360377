import React from 'react';
import './style.css';
import axios from '../../../lib/axios';
import { startRegistration } from '@simplewebauthn/browser';
import { useClickOutside } from '../../../lib/custom-hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { enc } from 'crypto-js';
import {
  decryptDataWithKey,
  deriveEncryptionKeyFromPassword,
  encryptDataWithKey,
  generateRandomSalt
} from '../../../lib/encryption';
import Button from '../../../component/Button';

const SetBiometricPopup = () => {
  const token = sessionStorage.getItem('accessToken');
  const popupRef = useRef(null);
  const navigate = useNavigate();

  useClickOutside(popupRef, () => {
    navigate(-1);
  });

  return (
    <div className="blur">
      <div ref={popupRef} className="popup register-fingerprint-container">
        <span>Register your fingerprint</span>
        <Button
          className="submit"
          onClick={async () => {
            const res = await axios.get('auth/options', {
              headers: {
                'authorization': token
              }
            });

            const { options } = res.data;
            const registrationresponse = await startRegistration(options);
            const biometricId = registrationresponse.id;
            await axios.post(
              'auth/verify',
              {
                response: registrationresponse,
                type: 'public-key'
              },
              {
                headers: {
                  'authorization': token
                }
              }
            );
            const dataKeyRes = await axios.get('user/data-Key');
            const encryptionDataStringified = dataKeyRes?.data?.encryptionData;
            if (encryptionDataStringified) {
              const { encryptedDataKey } = JSON.parse(encryptionDataStringified || null) || {};

              const derivedKey = enc.Base64.parse(sessionStorage.getItem('derivedKey') || '');
              if (!derivedKey) {
                throw new Error('Encryption key not found');
              }
              const dataKey = decryptDataWithKey(encryptedDataKey, derivedKey);
              const salt = generateRandomSalt(16);
              const biometricDerivedKey = deriveEncryptionKeyFromPassword(
                biometricId,
                salt,
                200000,
                16
              );
              const encryptedDataKeyWithBiomterics = encryptDataWithKey(
                dataKey,
                biometricDerivedKey
              );
              await axios.post('user/upsert/biomteric-datakey', {
                encryptionData: JSON.stringify({
                  encryptedDataKey: encryptedDataKeyWithBiomterics,
                  salt,
                  iterations: 200000,
                  length: 16
                })
              });
              sessionStorage.setItem('derivedKeyAlt', enc.Base64.stringify(biometricDerivedKey));
            }
            navigate(-1);
          }}>
          proceed
        </Button>
      </div>
    </div>
  );
};

export default SetBiometricPopup;
