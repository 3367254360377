import React, { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import MenuItem from '../../../component/menu-item';
import { useClickOutside } from '../../../lib/custom-hooks';
import './style.css';
import useConfigStore from '../../../state/config';

const JournalSettingsPopup = ({ setShowSettingsPopup }) => {
  const promptRef = useRef(null);

  useClickOutside(promptRef, () => {
    setShowSettingsPopup(false);
  });
  const [fonts, fontSize, setFontSize] = useConfigStore((state) => [
    state.fonts,
    state.fontSize,
    state.setFontSize
  ]);
  const [fontFamily, setFontFamily] = useConfigStore((state) => [
    state.fontFamily,
    state.setFontFamily
  ]);

  return (
    <div className="blur">
      <div ref={promptRef} className="font-settings-container popup">
        <div className="cancel-icon" onClick={() => setShowSettingsPopup(false)}>
          <FaTimes />
        </div>
        <div className="font-settings">
          <MenuItem title={'Size'} color="var(--font-primary)" />
          <input
            type="range"
            min="16"
            max="40"
            step="1"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          />
        </div>
        <div className="font-settings">
          <MenuItem title={'Style'} color="var(--font-primary)" />
          <div className="fonts">
            <select
              onChange={(e) => {
                setFontFamily(e.target.value);
              }}
              style={{ fontFamily: fontFamily }}
              value={fontFamily}>
              <option value=""> default</option>
              {fonts.map(({ name, value }) => (
                <option key={name} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalSettingsPopup;
