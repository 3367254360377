import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PromptSelector from '../../component/prompt-selector';
import { useAutosaveData, useClickOutside } from '../../lib/custom-hooks';
// import useJournalStore from '../../state/journals';
import { FaEllipsisV, FaChevronLeft, FaTrash, FaTag } from 'react-icons/fa';
import MenuItem from '../../component/menu-item';
import ConfirmDeletePopup from './confirm-delete-popup';
import JournalSettingsPopup from './journal-settings-popup';
import TagPopup from './tag-popup';
import Button from '../../component/Button';

const JournalHeader = ({ content, setContent, id, textareaRef, saveHandler, tags, setTags }) => {
  const navigate = useNavigate();
  const journalMenuRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const upsertJounal = useJournalStore((state) => state.upsertJounal);

  const [showPromptSelector, setShowPromptSelector] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const isInitialRender = useRef(true);

  const saveBtnRef = useRef(null);

  useClickOutside(journalMenuRef, () => setIsMenuOpen(false));

  useAutosaveData(
    () => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }

      if (saveBtnRef?.current) {
        saveBtnRef.current.click();
      }
    },
    1000,
    [content]
  );

  return (
    <div className="top-header">
      <div className="left-header">
        <FaChevronLeft
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <div ref={journalMenuRef} className="right-header">
        <Button
          btnRef={saveBtnRef}
          className="tag"
          style={{
            fontSize: '1.2rem',
            background: 'var(--accent-green)',
            marginRight: '10px',
            marginBlock: '0px',
            padding: '5px',
            color: 'var(--font-accent-green)',
            fontWeight: '300'
          }}
          onClick={async () => {
            if (content === '') {
              return;
            }
            await saveHandler();
            // navigate(-1);
          }}>
          save
        </Button>
        <FaEllipsisV onClick={() => setIsMenuOpen(!isMenuOpen)} />
        <div style={{ position: 'relative' }}>
          {isMenuOpen && (
            <div className="menu-container">
              <MenuItem
                title="Tags"
                onClick={() => {
                  setShowTagPopup(true);
                  setIsMenuOpen(false);
                }}
                icon={<FaTag />}
              />
              <MenuItem
                title="Delete"
                onClick={() => {
                  setShowDeletePopup(true);
                  setIsMenuOpen(false);
                }}
                icon={<FaTrash color="red" />}
              />
            </div>
          )}
        </div>
      </div>

      {showPromptSelector && (
        <PromptSelector
          content={content}
          setShowPromptSelector={setShowPromptSelector}
          setContent={setContent}
          textareaRef={textareaRef}
        />
      )}
      {showDeletePopup && <ConfirmDeletePopup id={id} setShowDeletePopup={setShowDeletePopup} />}
      {showSettingsPopup && <JournalSettingsPopup setShowSettingsPopup={setShowSettingsPopup} />}
      {showTagPopup && (
        <TagPopup setTags={setTags} jid={id} tags={tags} setShowTagPopup={setShowTagPopup} />
      )}
    </div>
  );
};

export default JournalHeader;
