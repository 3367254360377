import React from 'react';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

const Button = ({ onClick, children, style, btnRef, disabled }) => {
  const [loading, setLoading] = useState(false);
  return (
    <button
      ref={btnRef}
      style={{
        position: 'relative',
        ...style,
        ...(loading && { color: 'transparent' })
      }}
      disabled={loading || disabled}
      onClick={async (e) => {
        try {
          setLoading(true);
          await onClick(e);
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }}>
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          color: style?.color,
          opacity: 0.6,
          display: 'flex'
        }}>
        <ClipLoader size={20} loading={loading} />
      </span>
      {children}
    </button>
  );
};

export default Button;
