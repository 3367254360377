import { AES, lib, enc, PBKDF2, mode, pad } from 'crypto-js';

const generateRandomSalt = (len = 16) => {
  return lib.WordArray.random(len).toString();
};

const generateRandomEncryptionKey = (len = 32) => {
  const randomBytes = new Uint8Array(len);
  window.crypto.getRandomValues(randomBytes);
  const key = btoa(String.fromCharCode.apply(null, randomBytes));
  return key;
};

const deriveEncryptionKeyFromPassword = (password, salt, iterations, len = 16) => {
  const derivedKeyOptions = {
    keySize: len / 4,
    iterations
  };
  return PBKDF2(password, salt, derivedKeyOptions);
};

const encryptDataWithKey = (keyToEncrypt, derivedKey) => {
  const iv = lib.WordArray.random(16); // Generate a random initialization vector
  const encryptedKey = AES.encrypt(keyToEncrypt, derivedKey, {
    iv,
    padding: pad.Pkcs7,
    mode: mode.CBC
  });
  const message = iv.toString() + encryptedKey.toString();
  return message;
};

const decryptDataWithKey = (encryptedKey, derivedKey) => {
  if (!encryptedKey || !derivedKey) return;
  const iv = enc.Hex.parse(encryptedKey.substr(0, 32));
  const encrypted = encryptedKey.slice(32);

  const decrypted = AES.decrypt(encrypted || '', derivedKey, {
    iv,
    padding: pad.Pkcs7,
    mode: mode.CBC
  });

  return decrypted?.toString(enc.Utf8);
};

function generateEncryptionData(dataKey, password, iterations = 200000, length = 16) {
  const salt = generateRandomSalt();
  const derivedKey = deriveEncryptionKeyFromPassword(password, salt, 200000, 16); //to be saved in session storage
  const encryptedDataKeyWithDerivedKey = encryptDataWithKey(dataKey, derivedKey);
  return {
    derivedKey,
    encryptedDataKey: encryptedDataKeyWithDerivedKey,
    iterations,
    length,
    salt
  };
}

export {
  generateRandomEncryptionKey,
  deriveEncryptionKeyFromPassword,
  encryptDataWithKey,
  decryptDataWithKey,
  generateRandomSalt,
  generateEncryptionData
};
