import './App.css';
import { lazy, Suspense } from 'react';
import { JournalEditor } from './pages/journal-editor';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
const Sidebar = lazy(() => import('./component/sidebar/sidebar'));
const Settings = lazy(() => import('./pages/settings/home'));
const Timeline = lazy(() => import('./pages/home/timeline'));
const DisplaySettings = lazy(() => import('./pages/settings/display-settings'));
const PrivacySettings = lazy(() => import('./pages/settings/privacy-settings'));
const Feedback = lazy(() => import('./pages/feedback'));
const Tags = lazy(() => import('./pages/tags'));
const TagPage = lazy(() => import('./pages/tags/tag-page'));
const LandingPage = lazy(() => import('./pages/landing-page'));

import Home from './pages/home/home';
import useConfigStore from './state/config';
import PrivateRoute from './component/private-route';
import { ClipLoader } from 'react-spinners';
import ResetPasswordPopup from './pages/settings/privacy-settings/reset-password';
import SetBiometricPopup from './pages/settings/privacy-settings/set-biometric-popup';

function App() {
  // const [pageDate, setPageDate] = useState(moment());
  // const [chooseTemplate, setChooseTemplate] = useState(true);
  // const [selectedOption, setSelectedOption] = useState(null);

  const theme = useConfigStore((state) => state.theme);

  return (
    <div className={`App ${theme}`}>
      <BrowserRouter>
        <Sidebar />

        <Suspense
          fallback={
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <ClipLoader
                cssOverride={{
                  height: '100px',
                  width: '100px'
                }}
                color="var(--bg-accent-secondary)"
                loading={true}
              />
            </div>
          }>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route path="/tags">
              <Route
                index
                element={
                  <PrivateRoute>
                    <Tags />
                  </PrivateRoute>
                }
              />
              <Route
                path=":name"
                element={
                  <PrivateRoute>
                    <TagPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/timeline" element={<Timeline />} />
            {/* <Route path="/calendar" element={<Calendar />} /> */}

            <Route path="/settings">
              <Route
                index
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="display"
                element={
                  <PrivateRoute>
                    <DisplaySettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="privacy"
                element={
                  <PrivateRoute>
                    <PrivacySettings />
                  </PrivateRoute>
                }>
                <Route path="reset" element={<ResetPasswordPopup />} />
                <Route path="fingerprint" element={<SetBiometricPopup />} />
              </Route>
              <Route path="more" element={<h1> more</h1>} />
            </Route>
            {/* <Route path="/quotes" element={<Quotes />} /> */}
            <Route
              path="/journal"
              element={
                <PrivateRoute>
                  <JournalEditor />
                </PrivateRoute>
              }
            />
            <Route path="/about" element={<LandingPage />} />

            <Route path="/feedback" element={<Feedback />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
