import React, { useState } from 'react';
import '../style.css';
import axios from '../../../lib/axios';
import { ClipLoader } from 'react-spinners';
import useConfigStore from '../../../state/config';
import { useNavigate } from 'react-router-dom';
import { startAuthentication } from '@simplewebauthn/browser';
import { deriveEncryptionKeyFromPassword } from '../../../lib/encryption';
import { enc } from 'crypto-js';

const Login = ({ setIsLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const setUser = useConfigStore((state) => state.setUser);

  const navigate = useNavigate(null);
  function resetForm() {
    setPassword('');
    setUsername('');
  }

  const formHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post('user/login', {
        username,
        password
      });

      const { user, access_token } = res.data;
      if (!user || !access_token) {
        throw new Error('User not found');
      }
      setUser(user);
      sessionStorage.setItem('accessToken', access_token);
      const encryptionRes = await axios.get('user/data-Key');
      const encryptionDataStringified = encryptionRes.data?.encryptionData;
      const { salt, iterations, length } = JSON.parse(encryptionDataStringified) || {};
      const derivedKey = deriveEncryptionKeyFromPassword(password, salt, iterations, length);
      sessionStorage.setItem('derivedKey', enc.Base64.stringify(derivedKey));
      navigate('/');
    } catch (error) {
      let message = error?.response?.data?.message;
      if (!message) {
        message = error.message;
      }
      setErrors((e) => Array.from(new Set([...e, message])));
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <div className="popup register-container">
      <form onSubmit={formHandler}>
        <input
          id="username"
          required
          autoComplete="off"
          placeholder="username"
          autoFocus
          value={username}
          onBlur={async () => {
            if (!username) return;

            const res = await axios.get(`auth/authentication/options/${username}`);
            const { options } = res.data;

            const authenticationRes = await startAuthentication(options);
            const verificationResp = await axios.post('auth/authentication/verify', {
              response: authenticationRes,
              username: username,
              credentialID: authenticationRes.id
            });

            const biometricId = authenticationRes.id;
            const { verified, user, access_token: accessToken } = verificationResp.data;
            if (!user || !accessToken) {
              throw new Error('User not found');
            }
            if (verified) {
              setUser(user);
              sessionStorage.setItem('accessToken', accessToken);

              const encryptionRes = await axios.get('user/biometric-data-Key');
              const encryptionDataStringified = encryptionRes.data?.encryptionData;
              const { salt, iterations, length } = JSON.parse(encryptionDataStringified) || {};
              const derivedKey = deriveEncryptionKeyFromPassword(
                biometricId,
                salt,
                iterations,
                length
              );
              sessionStorage.setItem('derivedKeyAlt', enc.Base64.stringify(derivedKey));

              navigate('/');
            }
          }}
          onChange={(e) => {
            setErrors([]);
            setUsername(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="password"
          required
          value={password}
          onChange={(e) => {
            setErrors([]);
            setPassword(e.target.value);
          }}
        />
        {errors?.map((e, i) => (
          <span key={i}>{e}</span>
        ))}
        {loading ? (
          <ClipLoader
            cssOverride={{ margin: 'auto' }}
            color="var(--bg-accent-secondary)"
            loading={loading}
          />
        ) : (
          <button type="submit">Login</button>
        )}

        <hr className="divider" />
        <span
          className="secondary-submit"
          onClick={() => {
            setIsLogin(false);
          }}>
          Create Account
        </span>
      </form>
    </div>
  );
};

export default Login;
