import React, { useRef, useState } from 'react';
import { FaPencilAlt, FaTimes, FaTrash } from 'react-icons/fa';
import { useClickOutside } from '../../lib/custom-hooks';
import './style.css';
import useTemplateStore from '../../state/template';
import { insertTextAtCursor } from '../../lib/util';
import AddPrompt from './add-prompt';

const PromptSelector = ({ setShowPromptSelector, content, setContent, textareaRef }) => {
  const promptRef = useRef(null);
  const [TemplateItems, deleteTemplate] = useTemplateStore((state) => [
    state.templates,
    state.deleteTemplate
  ]);

  const promptClickHandler = (e, promptKey) => {
    //using textarea ref instead of event.target.selectionStart
    // because e.target represents the prompt button
    const updatedText = insertTextAtCursor(textareaRef, content, TemplateItems[promptKey]);
    setContent(updatedText);
    setShowPromptSelector(false);
  };

  const [showAddPromptPopup, setShowAddPromptPopup] = useState(false);

  useClickOutside(promptRef, () => {
    if (showAddPromptPopup) {
      return;
    }
    setShowPromptSelector(false);
  });

  return (
    <div className="blur">
      <div ref={promptRef} className="template-selector-container popup">
        <div className="cancel-icon" onClick={() => setShowPromptSelector(false)}>
          <FaTimes />
        </div>
        <p> Select Prompt</p>
        {Object.keys(TemplateItems).map((promptKey, i) => (
          <div key={i}>
            <div className="menu-item-left" onClick={(e) => promptClickHandler(e, promptKey)}>
              <FaPencilAlt style={{ fontSize: '22px' }} />
              <span>{promptKey.replace(/-/g, ' ')}</span>
            </div>
            <FaTrash
              onClick={() => {
                deleteTemplate(promptKey);
              }}
              style={{ color: 'var(--accent-red)', cursor: 'pointer' }}
            />
          </div>
        ))}
        {Object.keys(TemplateItems).length < 5 && (
          <button
            className="add-prompt-btn"
            onClick={() => {
              setShowAddPromptPopup(true);
            }}>
            <span>Add Prompt</span>
          </button>
        )}
        {showAddPromptPopup && <AddPrompt setShowAddPromptPopup={setShowAddPromptPopup} />}
      </div>
    </div>
  );
};

export default PromptSelector;
