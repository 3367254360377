import { create } from 'zustand';

const initState = {
  '5-min-journal': `\nI'm grateful for?\n\n
What would make today great?\n\n
Your daily affirmations?\n\n
amazing things that happened today?\n\n
How could I have made today better?\n`,

  'Jagruk-Journal': `\nI'll remember today by?\n\n
To-Do List\n1\n2\n3\n
Expenses\n1\n2\n3\n`,

  'Gratitude-Diary': `\n
Who or What do you want to express your gratitude to?\n\n
Why do you feel thankful?\n\n
Thanks to them I feel\n`
};

const templateStore = (set) => ({
  templates: initState,
  reset: () => {
    localStorage.removeItem('templates');
    set({ templates: initState });
  },
  addTemplate: (title, text) =>
    set((state) => {
      state.templates[title] = text;
      return state;
    }),
  deleteTemplate: (title) =>
    set((state) => {
      delete state.templates[title];
      return { templates: state.templates };
    })
});

export default create(templateStore);
