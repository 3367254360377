import React from 'react';
import './style.css';

const MenuItem = ({ title, icon, onClick, color }) => {
  return (
    <div className="menu-item" onClick={onClick} style={{ color }}>
      {icon}
      <span className="title">{title}</span>
    </div>
  );
};

export default MenuItem;
