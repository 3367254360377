import React, { useRef, useState } from 'react';
import './style.css';
import { useLocation } from 'react-router-dom';
import { toJournalDateFormat } from '../../lib/dates';
import useJournalStore from '../../state/journals';
// import useConfigStore from '../../state/config';
import JournalHeader from './journal-header';
import { FaTag } from 'react-icons/fa';
import RichEditor from './rich-editor';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect } from 'react';
import { BounceLoader } from 'react-spinners';

function transformEditorStateToString(state) {
  const raw = convertToRaw(state.getCurrentContent());
  return JSON.stringify(raw);
}

export const JournalEditor = () => {
  const { id: initialId } = useLocation().state || {};
  const [id, setId] = useState(initialId || '');
  //getting action atomically from state is more optimal according to to docs
  const upsertJounal = useJournalStore((state) => state.upsertJounal);
  const getJournalById = useJournalStore((state) => state.getJournalById);
  const [date, setDate] = useState(toJournalDateFormat());
  const textareaRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    getJournalById(id)
      .then((res) => {
        const {
          // id: _id,
          // title: initTitle,
          content: initContent,
          tags: initTags = [],
          // created_at: initCreatedAt,
          date: initDate
        } = res;
        setTags(initTags);

        let editorStateContent;
        try {
          editorStateContent = convertFromRaw(JSON.parse(initContent));
        } catch (error) {
          editorStateContent = ContentState.createFromText(initContent);
        }
        const initialEditorState = initContent
          ? EditorState.createWithContent(editorStateContent)
          : EditorState.createEmpty();
        setEditorState(initialEditorState);
        setDate(initDate ? initDate.slice(0, 10) : toJournalDateFormat(new Date()));
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);
  return (
    <div className="journal-container">
      <JournalHeader
        content={editorState}
        // setContent={setContent}
        tags={tags}
        setTags={setTags}
        id={id}
        textareaRef={textareaRef}
        saveHandler={async () => {
          const res = await upsertJounal({
            id,
            content: transformEditorStateToString(editorState),
            date
          });
          // console.log('saving the journal', res);
          setId(res._id);
        }}
      />
      <div className="date-selector">
        <input
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
      </div>
      {loading ? (
        <BounceLoader
          cssOverride={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            opacity: '0.5'
          }}
          color="var(--bg-accent)"
          size={80}
        />
      ) : (
        <RichEditor editorState={editorState} setEditorState={setEditorState} />
      )}
      {/* <textarea
        style={{ fontSize: `${fontSize}px`, fontFamily }}
        onChange={(e) => {
          const len = textareaRef?.current.selectionStart;
          const cursorPos = len || 0;
          let updatedCursorPos = cursorPos;
          let str = '';
          if (len >= 2) {
            str = e.target.value.substring(len - 2, len);
            if (str === '@@') {
              str =
                ' ' +
                new Date().toLocaleString('en-us', {
                  hour12: true,
                  dateStyle: 'medium',
                  timeStyle: 'short'
                });
              e.target.value =
                e.target.value.substring(0, len - 2) + str + e.target.value.substring(len);
              updatedCursorPos += str.length - 2;
            }
          }
          setContent(e.target.value);
          textareaRef.current.setSelectionRange(updatedCursorPos, updatedCursorPos);
        }}
        value={content}
        autoFocus
        onFocus={placeCursorAtEnd}
        placeholder="write your story..."
        ref={textareaRef}
      /> */}
      <div className="tags">
        {tags.map((tag, i) => (
          <span className="tag" key={i}>
            <FaTag />
            {tag.key}
          </span>
        ))}
      </div>
    </div>
  );
};
